.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.mintTokens {
  display: flex;
  flex-direction: column;
  gap: 30px;
}