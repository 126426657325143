.wrapper {
  margin: 0 auto;
  max-width: 480px;
  width: 100%;

  .formWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .description {
      font-size: 12px;
      opacity: 0.7;
      margin-top: 6px;
    }
  }
}