.wrapper {
  margin: 0 auto;
  max-width: 480px;
  width: 100%;

  .formWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .separator {
      margin-top: 24px;
      margin-bottom: -12px;
      opacity: 0.7;
    }

    .connectBtns {
      width: 100%;
    }
  }
}