.desktopRowWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.1fr;
  text-align: left;
  padding-top: 28px;
  padding-bottom: 28px;
  align-items: center;

  @media (max-width: 920px) {
    display: none;
  }

  .date {
    text-align: right;
    opacity: 0.7;
  }

  .amount {
    font-weight: 600;
    font-size: 16px;
  }

  .link {
    opacity: 0.8;
    text-decoration: underline;

    &:hover {
      opacity: 1;
    }
  }

  .type {
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: #c4dcff29;
    padding: 4px 12px;
    border-radius: 20px;
  }

  .currency {
    opacity: 0.4;
    font-size: 12px;
  }
}

.mobileRowWrapper {
  display: none;

  @media (max-width: 920px) {
    display: flex;
  }

  margin-bottom: 20px;
  padding: 16px;
  background-color: rgba(196, 220, 255, 0.052);
  border-radius: 20px;
  flex-direction: column;

  @media (max-width: 540px) {
    padding: 8px;
  }

  .rowList {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c6d3ff24;
    width: 100%;
    padding: 20px;

    &:last-child {
      border-bottom: none;
    }

    .title {
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      opacity: 0.7;
      letter-spacing: 1px;
      text-align: left;
    }

    .content {
      text-align: right;

      .date {
        text-align: right;
        opacity: 0.7;
      }

      .amount {
        font-weight: 600;
        font-size: 16px;
      }

      .link {
        opacity: 0.8;
        text-decoration: underline;

        &:hover {
          opacity: 1;
        }
      }

      .type {
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 1px;
        background-color: #c4dcff29;
        padding: 4px 12px;
        border-radius: 20px;
      }

      .currency {
        opacity: 0.4;
        font-size: 12px;
      }
    }
  }
}
