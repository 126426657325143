.wrapper {
  padding: 24px;

  @media (max-width: 920px) {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .iconWrapper {
    width: 44px;
    height: 44px;
    background-color: rgba(196, 220, 255, 0.15);
    border-radius: 14px;
    padding: 11px;

    @media (max-width: 920px) {
      width: 56px;
      height: 56px;
    }
  }

  .textContainer {
    margin-top: 20px;

    @media (max-width: 920px) {
      margin-top: 0;
    }

    .amount {
      font-size: 28px;
    }

    .currency {
      font-size: 14px;
      opacity: 0.8;
    }

    .title {
      margin-top: 8px;
      font-weight: 500;
      font-size: 12px;
      opacity: 0.5;
    }
  }
}
