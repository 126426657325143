.sendCodeButton {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
  margin-top: 6px;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
