.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  .qrCodeWrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
  }

  .description {
    font-size: 14px;
    opacity: 0.7;
    text-align: center;
    line-height: 18px;
  }
}
