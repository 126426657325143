.wrapper {
  font-size: 18px;
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
  margin-top: 16px;
  text-align: center;
  opacity: 0.6;

  @media (max-width: 540px) {
    font-size: 12px;
  }

  &:hover {
    opacity: 1;
  }
}