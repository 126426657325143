.button {
  padding: 12px 24px;
  font-size: 13px;
  text-transform: uppercase;
  background-color: rgba(196, 220, 255, 0.25);
  font-weight: 600;
  letter-spacing: 1.5px;
  border: 1px solid #778499;
  border-radius: 14px;
  opacity: 0.9;
  cursor: pointer;

  @media (max-width: 540px) {
    font-size: 9px;
  }

  &:hover {
    opacity: 1;
  }
}