.wrapper {
  padding: 14px 24px;
  background-color: rgba(196, 220, 255, 0.15);
  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  display: flex;
  gap: 12px;
}

.iconWrapper {
  height: 14px;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}
