.mintToken {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 300px;

  .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inputBox {
    display: flex;
    align-items: center;

    input {
      width: 100%;
    }
  }
}