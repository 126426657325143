.submitButton {
  margin-top: 32px;
  width: 100%;
  padding: 16px;
  border: 1px solid #fff;
  border-radius: 21px;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  background-color: #fff;
  color: #04071b;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0.9;

  @media (max-width: 540px) {
    margin-top: 26px;
    font-size: 14px;
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}