.wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  .imgWrapper {
    width: 16px;
  }

  .copied {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    opacity: 0;
    animation: fadeInOut 1s forwards;
    font-weight: 500;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translate(-50%, -10px);
    }
    10% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    90% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -10px);
    }
  }
}
