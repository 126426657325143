.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  .iconWrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 120px;
    height: 120px;
    background-color: rgba(196, 220, 255, 0.15);
    border-radius: 50%;
    padding: 40px;
  }

  .description {
    font-size: 14px;
    opacity: 0.7;
    text-align: center;
    line-height: 18px;
  }
}
