.walletsGrid {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  position: relative;
  margin-top: 16px;

  .gridOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #22252a;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 1px;
    color: #ffffff;
  }

  .walletBtn {
    background-color: rgba(196, 220, 255, 0.15);
    border-radius: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    cursor: pointer;
    opacity: 0.9;
    font-size: 14px;
    letter-spacing: 0.5px;

    &:hover {
      opacity: 1;
    }

    .iconWrapper {
      height: 32px;
      padding-right: 20px;
      text-align: left;
    }
  }
}