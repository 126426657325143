.wrapper {
  margin: 0 auto;
  max-width: 480px;
  width: 100%;

  .formWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .description {
      font-size: 12px;
      opacity: 0.7;
      margin-top: 6px;
    }
  }
}

.btnLink {
  margin-top: 32px;
  width: 100%;
  padding: 16px;
  border: 1px solid #fff;
  border-radius: 21px;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  background-color: #fff;
  color: #04071b;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0.9;
  text-align: center;

  @media (max-width: 540px) {
    font-size: 12px;
  }

  &:hover {
    opacity: 1;
  }
}