.wrapper {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(196, 220, 255, 0.2);
  }

  @media (max-width: 920px) {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    gap: 24px;
  }

  @media (max-width: 540px) {
    gap: 16px;
  }

  .leftSection {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 70%;

    .title {
      font-size: 16px;
      font-weight: bold;

      @media (max-width: 540px) {
        font-size: 14px;
      }
    }

    .description {
      font-size: 14px;
      opacity: 0.7;
      font-weight: 400;
      line-height: 18px;

      @media (max-width: 540px) {
        font-size: 12px;
      }
    }
  }

  .email {
    padding-top: 18px;
    height: 40px;

    .content {
      border-radius: 14px;
      background-color: rgba(4, 8, 13, 0.3);
      padding: 12px 20px;
      color: #ffffff79;
    }
  }

  .disabledBtn {
    padding-top: 18px;
    height: 40px;
    border-radius: 14px;
    background-color: rgba(4, 8, 13, 0.3);
    padding: 12px 20px;
    color: #ffffff79;
  }
}
