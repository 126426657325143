.wrapper {
  margin: 0 auto;

  .stepWrapper {
    padding: 20px;
    text-align: center;
    min-height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .imgWrapper {
      width: 90px;
      height: 90px;
    }

    .header {
      font-size: 32px;
      font-weight: 600;
    }

    .description {
      font-size: 14px;
      opacity: 0.7;
      width: 80%;
      line-height: 18px;
    }

    .navLink {
      margin-top: 12px;
      text-decoration: underline;
      font-size: 16px;
      text-transform: uppercase;
      opacity: 0.7;
    }
  }
}