.wrapper {
  display: flex;
  gap: 12px;

  .desktopOnly {
    @media (max-width: 920px) {
      display: none !important;
    }
  }

  .headerBtn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(196, 220, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.9;

    @media (max-width: 540px) {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: rgba(196, 220, 255, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0.9;
    }

    &:hover {
      opacity: 1;
    }

    .iconWrapper {
      height: 24px;

      @media (max-width: 540px) {
        height: 24px;
      }
    }
  }
}

.connectLink {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  border-radius: 28px;
  background-color: rgba(196, 220, 255, 0.15);
  opacity: 0.9;

  @media (max-width: 540px) {
    padding: 16px 32px;
    // margin-right: 8px;
  }

  &.wrongAddress {
    background-color: #e85353;
    color: #fff;
  }

  &:hover {
    opacity: 1;
  }

  .iconWrapper {
    width: 23px;

    @media (max-width: 540px) {
      width: 16px;
    }
  }

  .text {
    font-size: 14px;

    @media (max-width: 540px) {
      font-size: 14px;
    }
  }
}
