.inputWrapper {
  margin-top: 20px;
  width: 100%;

  .label {
    opacity: 0.7;
    margin-bottom: 6px;
    text-align: left;

    @media (max-width: 540px) {
      font-size: 12px;
    }
  }

  .inputStyles {
    background-color: rgba(196, 220, 255, 0.15);
    border-radius: 14px;
    width: 100%;
    font-size: 16px;
    color: #fff;
    padding: 12px;
  }

  :disabled {
    opacity: 0.5;
  }

  .errorMessage {
    font-size: 12px;
    text-align: left;
    margin-top: 2px;
    font-weight: 500;
    color: #e96666;
  }
}