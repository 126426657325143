.wrapper {
  background-color: #e85353;
  padding: 16px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 24px;
  margin-bottom: -8px;
}
