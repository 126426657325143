.button {
  display: flex;
  align-items: center;
  position: relative;
  padding: 3px;

  .arrowIcon {
    padding-left: 8px;
  }
}

.currencyWrapper {
  display: flex;
  align-items: center;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .logoWrapper {
    width: 24px;
    height: 24px;
  }

  .name {
    font-weight: 500;
    font-size: 16px;
    color: #ebeced;
    padding-left: 6px;
  }
}
