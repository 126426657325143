.wrapper {
  padding: 24px;
  cursor: pointer;

  .summary {
    display: flex;
    justify-content: space-between;

    .leftSection {
      display: flex;
      align-items: center;
      gap: 28px;

      .datesWrapper {
        display: flex;
        flex-direction: column;

        .status {
          span {
            font-size: 8px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
            background-color: #ffffff2e;
            display: inline-block;
            padding: 4px 8px;
            border-radius: 4px;
          }
        }

        .dates {
          margin-top: 8px;
          font-weight: 500;
          font-size: 16px;

          @media (max-width: 540px) {
            font-size: 12px;
          }
        }
      }

      .amount {
        border-left: 1px solid #ffffff45;
        padding-left: 28px;
        .title {
          padding: 4px;
          opacity: 0.5;
          font-size: 9px;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .value {
          margin-top: 6px;
          font-weight: 500;
          font-size: 18px;

          @media (max-width: 540px) {
            font-size: 14px;
          }
        }
      }

      .apr {
        border-left: 1px solid #ffffff45;
        padding-left: 28px;
        .title {
          padding: 4px;
          opacity: 0.5;
          font-size: 9px;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .value {
          margin-top: 6px;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }

    .arrow {
      display: flex;
      align-items: center;
    }
  }

  .details {
    margin-top: 24px;
    border-top: 1px solid #ffffff45;
    padding-top: 24px;
    display: flex;
    gap: 24px;

    @media (max-width: 540px) {
      flex-direction: column;
    }

    .leftSection {
      padding-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 540px) {
        padding-top: 0px;
      }

      .day {
        .date {
          font-weight: 500;
          font-size: 14px;
          opacity: 0.6;
          margin-bottom: 6px;

          @media (max-width: 540px) {
            font-size: 12px;
          }
        }
        .dayInfo {
          background-color: #ffffff17;
          max-width: 468px;
          width: 100%;
          padding: 12px 20px;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;

          .balance {
            .title {
              font-size: 12px;
              opacity: 0.4;
              font-weight: 500;
            }

            .value {
              margin-top: 4px;
              font-size: 18px;
              font-weight: 500;

              @media (max-width: 540px) {
                font-size: 16px;
              }
            }
          }

          .reward {
            .title {
              font-size: 12px;
              opacity: 0.4;
              font-weight: 500;
            }

            .value {
              margin-top: 4px;
              font-size: 18px;
              font-weight: 500;

              @media (max-width: 540px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .rightSection {
      padding-top: 16px;
      padding-left: 24px;
      border-left: 1px solid #ffffff45;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .stat {
        display: flex;
        justify-content: space-between;

        .title {
          font-weight: 500;
          opacity: 0.5;
        }

        .value {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
