.item {
  padding: 16px;
  background-color: rgba(196, 220, 255, 0.052);
  border-radius: 20px;

  .status {
    display: inline-block;
    padding: 12px;
    border-radius: 14px;
    margin-bottom: 10px;
    background-color: rgba(196, 220, 255, 0.052);
  }

  .desktopDetails {
    margin-top: 12px;
    padding: 0 12px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 5px;

    @media (max-width: 920px) {
      display: none;
    }

    .title {
      font-size: 14px;
      opacity: 0.4;
    }

    .value {
      margin-top: 4px;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .mobileDetails {
    display: none;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0;

    @media (max-width: 920px) {
      display: flex;
    }

    .dataWrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .header {
        opacity: 0.4;
      }

      .value {
        font-size: 16px;
      }
    }
  }

  .button {
    margin-top: 12px;
    width: 100%;
    padding: 12px;
    border: 1px solid #fff;
    border-radius: 18px;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
    background-color: #fff;
    color: #04071b;
    cursor: pointer;
    transition: background-color 0.3s;
    opacity: 0.9;

    @media (max-width: 920px) {
      font-size: 14px;
    }

    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  .currency {
    opacity: 0.6;
    font-size: 14px;
  }
}
