.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;

  .step {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: rgba(196, 220, 255, 0.15);
    opacity: 0.3;
    transition: opacity 0.3s;

    &.active {
      opacity: 1;
      background-color: rgba(196, 220, 255, 0.15);
      color: #fff;
    }
  }

  .spacer {
    height: 2px;
    background-color: rgba(196, 220, 255, 0.15);
    flex-grow: 1;
    margin: 0 8px;
    opacity: 0.3;
    transition: opacity 0.3s;

    &.active {
      opacity: 1;
      background-color: rgba(196, 220, 255, 0.267);
      color: #fff;
    }
  }
}
