.desktopWrapper {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 920px) {
    display: none;
  }

  @media (max-width: 540px) {
    padding: 8px 0px;
  }

  .leftSection {
    display: flex;
    align-items: center;

    .logoWrapper {
      height: 42px;
      width: 42px;
    }

    .linkItems {
      padding-left: 40px;
      display: flex;
      gap: 28px;

      a {
        font-size: 16px;
        opacity: 0.7;

        &:hover {
          opacity: 0.9;
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }
}

.mobileWrapper {
  display: none;
  padding: 20px 0;
  justify-content: space-between;

  @media (max-width: 920px) {
    display: flex;
  }

  .leftSection {
    display: flex;
    align-items: center;
    gap: 12px;

    .logoWrapper {
      height: 36px;
      width: 36px;
    }
  }

  .rightSection {
    display: flex;
  }

  .headerBtn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(196, 220, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.9;
    position: relative;

    &.menuItem {
      margin-left: 10px;
    }

    .droplinkItems {
      position: absolute;
      top: 60px;
      right: 0;
      padding: 24px;
      background-color: #1d2126;
      z-index: 99999;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 200px;
      font-size: 16px;
    }

    &:hover {
      opacity: 1;
    }

    .iconWrapper {
      height: 24px;
      // padding-left: 2px;

      @media (max-width: 540px) {
        height: 20px;
      }
    }
  }
}
