.paymentWrapper {
  background-color: rgba(196, 220, 255, 0.15);
  border-radius: 20px;
  width: 100%;
  padding: 18px;
  margin-bottom: 4px;
  position: relative;

  .direction {
    position: absolute;
    // padding: 8px 10px 8px 10px;
    // border-radius: 50%;
    // background-color: rgba(196, 220, 255, 0.15);
    cursor: pointer;

    bottom: -20px;
    left: calc(50% - 15px);
  }

  .title {
    font-size: 13px;
    opacity: 0.7;
    margin-bottom: 16px;
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .amountInput {
      background-color: transparent;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      width: 60%;
    }
  }

  .balanceAmount {
    margin-top: 12px;
    margin-bottom: 8px;
    text-align: right;
    font-size: 13px;
    opacity: 0.7;
    text-decoration: underline;
    cursor: pointer;

    &.quoteCurrency {
      text-decoration: none;
      cursor: default;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .error {
    color: #e85353;
  }
}
