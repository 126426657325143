@font-face {
  font-family: "Inter";
  font-weight: normal;
  src:
    local("Inter-Regular"),
    url("../fonts/Inter/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Regular.woff") format("woff"),
    url("../fonts/Inter/Inter-Regular.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src:
    local("Inter-ExtraBold"),
    url("../fonts/Inter/Inter-ExtraBold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraBold.woff") format("woff"),
    url("../fonts/Inter/Inter-ExtraBold.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-weight: 900;
  src:
    local("Inter-Black"),
    url("../fonts/Inter/Inter-Black.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Black.woff") format("woff"),
    url("../fonts/Inter/Inter-Black.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-weight: bold;
  src:
    local("Inter-Bold"),
    url("../fonts/Inter/Inter-Bold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Bold.woff") format("woff"),
    url("../fonts/Inter/Inter-Bold.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src:
    local("Inter-Medium"),
    url("../fonts/Inter/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Medium.woff") format("woff"),
    url("../fonts/Inter/Inter-Medium.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src:
    local("Inter-SemiBold"),
    url("../fonts/Inter/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-SemiBold.woff") format("woff"),
    url("../fonts/Inter/Inter-SemiBold.ttf") format("opentype");
  font-style: normal;
}
