@import "reset";
@import "fonts";

body {
  font-family: "Inter";
  font-weight: normal;
  font-size: 14px;
  color: #fff;
  margin: 0;
  background-color: #050505;

  a {
    color: #fff;
    text-decoration: none;
  }

  button {
    border: none;
  }

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(19px);
    z-index: 0;
    pointer-events: none;
  }

  #root {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .apexcharts-tooltip {
    padding: 12px 24px !important;
    border-radius: 12px;
    background-color: #3c404b !important;
    border: 1px solid #76849958 !important;
    box-shadow: 0 4px 6px #21252b84;

    .apexcharts-tooltip-title {
      background-color: #3c404b !important;
      border-bottom: 1px solid #76849958 !important;
    }
  }

  #aprChart {
    .apexcharts-bar-area:hover {
      fill: #5d42d1;
    }
  }

  .apexcharts-tooltip-marker {
    background-color: #21252b !important;
  }

  .highcharts-reset-zoom {
    background: #3c404b !important;
    color: white !important;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .highcharts-reset-zoom:hover {
    background: #555;
  }

  .highcharts-navigator-xaxis text {
    fill: #ffffff;
    filter: none;
  }

  .highcharts-navigator-xaxis .highcharts-text-outline {
    fill: none;
    stroke: none;
  }

  .highcharts-range-selector-group {
    display: none;
  }

  h1 {
    font-weight: 500;
    font-size: 80px;
    letter-spacing: 5px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 28px;

    @media (max-width: 920px) {
      font-size: 60px;
      letter-spacing: 3px;
    }

    @media (max-width: 540px) {
      font-size: 40px;
      letter-spacing: 2px;
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }

  h2 {
    font-size: 48px;
  }

  h4 {
    padding: 24px;
    padding-bottom: 0px;
    font-size: 20px;
    font-weight: 600;

    @media (max-width: 540px) {
      font-size: 16px;
    }
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 28px;
  }

  h6 {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 28px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  form {
    width: 100%;
  }

  ::placeholder {
    color: #fff;
    opacity: 0.5;
    /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: rgba(255, 255, 255, 0.514);
  }
}