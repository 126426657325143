.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #25292fec;
  border-radius: 30px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .imgWrapper {
    width: 180px;
    height: 180px;
  }

  .text {
    opacity: 0.7;
  }
}
