.wrapper {
  .paymentInfo {
    margin-top: 8px;
    margin-bottom: -20px;
    padding: 18px;
    background-color: rgba(4, 8, 13, 0.6);
    border-radius: 20px;

    .price {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;

      @media (max-width: 540px) {
        font-size: 13px;
      }

      .currency {
        opacity: 0.6;
      }
    }

    .blockchainInfo {
      .blockchainInfoItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 13px;
        padding: 12px 0;

        @media (max-width: 540px) {
          font-size: 11px;
        }

        .title {
          letter-spacing: 1px;
          opacity: 0.5;
        }

        .value {
          font-size: 14px;

          @media (max-width: 540px) {
            font-size: 12px;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #ffffff50;
        }
      }
    }
  }
}