.intervalSelector {
  display: flex;
  gap: 10px;
  font-size: 12px;

  @media (max-width: 920px) {
    margin-top: 12px;
  }
}

.interval {
  padding: 4px 8px;
  cursor: pointer;
  background-color: #f0f0f016;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  user-select: none;
  opacity: 0.5;
}

.interval:hover {
  background-color: #f0f0f054;
}

.active {
  background-color: #f0f0f016;
  color: white;
  font-weight: bold;
  opacity: 1;
}
