.wrapper {
  padding: 180px 0 100px 0;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  text-align: left;
  font-size: 16px;

  @media (max-width: 540px) {
    padding: 80px 0 20px 0;
  }

  .title {
    text-transform: uppercase;
    opacity: 0.4;
    padding-bottom: 8px;
    font-size: 14px;

    @media (max-width: 540px) {
      padding-bottom: 0px;
    }
  }

  a {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .logoWrapper {
    height: 42px;
    width: 42px;
  }

  .columnSection {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .socialList {
    display: flex;
    gap: 20px;
    padding-top: 12px;
  }
}

.desktopWrapper {
  display: block;

  @media (max-width: 540px) {
    display: none;
  }
}

.mobileWrapper {
  display: none;

  .wrapper {
    display: flex;
    flex-direction: column;

    .row {
      margin-top: 40px;
      margin-bottom: 40px;
      display: flex;
      gap: 100px;
    }

    .socialSection {
      margin-top: 40px;
    }
  }

  @media (max-width: 540px) {
    display: block;
  }
}
