.wrapper {
  width: 100%;
  margin-top: 32px;

  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.1fr;
    text-align: left;

    @media (max-width: 920px) {
      display: none;
    }

    .title {
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      opacity: 0.7;
      letter-spacing: 1px;

      &.right {
        text-align: right;
      }
    }
  }

  .tableWrapper {
    margin-top: 24px;

    .items {
      border-bottom: 1px solid #c6d3ff24;

      @media (max-width: 920px) {
        border-bottom: none;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.pagination {
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 4px;


  button {
    border-radius: 4px;
    padding: 6px 12px;
    background-color: #ffffff10;
    color: #fff;
    opacity: 0.4;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &.activePage {
      opacity: 0.9;
    }
  }
}

.noData {
  text-align: center;
  opacity: 0.6;
}