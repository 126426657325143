.wrapper {
  padding: 24px;
  // position: relative;

  .tabList {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .tab {
    padding: 12px 16px;
    cursor: pointer;
    opacity: 0.7;
    border: 1px solid #c6d3ff07;
    border-radius: 14px;

    &.active {
      background-color: rgba(196, 220, 255, 0.15);
      border-radius: 14px;
      opacity: 1;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .tabContent {}

  .actionBtns {
    position: absolute;
    right: 24px;
    top: 24px;
    display: flex;
    gap: 12px;

    @media (max-width: 920px) {
      display: none;
    }
  }
}