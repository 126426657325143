.wrapper {
  max-width: 856px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .topSection {
    width: 100%;

    .infoWrapper {
      padding: 40px;

      @media (max-width: 540px) {
        padding: 16px;
      }

      .stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ffffff53;
        padding-bottom: 32px;

        @media (max-width: 540px) {
          font-size: 14px;
          justify-content: space-around;
          padding-bottom: 20px;
        }

        .title {
          font-size: 14px;
          opacity: 0.6;
          margin-bottom: 8px;

          @media (max-width: 540px) {
            font-size: 8px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }

        .value {
          font-size: 28px;

          @media (max-width: 540px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .infoText {
    padding: 24px;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.5;

    @media (max-width: 540px) {
      padding: 16px;
      font-size: 12px;
      line-height: 18px;
    }
  }
}