.wrapper {
  // margin-top: 40px;

  @media (max-width: 920px) {
    margin-top: 24px;
  }

  .statsWrapper {
    margin: 24px 0;
    display: flex;
    gap: 12px;

    @media (max-width: 920px) {
      margin: 16px 0;
      flex-direction: column;
    }
  }
}