.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .innerCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 16px;

    @media (max-width: 920px) {
      padding: 28px 0;
      gap: 8px;
    }

    .rateValue {
      font-weight: 600;
      font-size: 48px;

      @media (max-width: 920px) {
        font-size: 28px;
      }
    }

    .rateTitle {
      opacity: 0.6;
    }
  }
}
