.snapshots {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .pagination {
    display: flex;
    align-items: center;
    gap: 12px;

    margin-left: auto;

    .nav-btn {
      padding: 5px 10px;

      border-radius: 3px;
      border: none;
      outline: none;

      background-color: rgba(196, 220, 255, 0.15);
      color: white;

      cursor: pointer;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}