.wrapper {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: 540px) {
    padding: 0 16px;
  }
}
