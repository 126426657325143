.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  .qrCodeWrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
  }

  .description {
    font-size: 14px;
    opacity: 0.7;
    line-height: 18px;
  }

  .additional {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
  }

  .appLinkBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: 920px) {
      gap: 16px;
    }

    .appLinkBtn {
      width: 232px;
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
      border-radius: 14px;
      padding: 16px;
      background-color: #050505;

      .iconWrapper {
        height: 18px;
      }
    }
  }
}