.select {
  position: relative;

  .dropdown {
    margin-top: 8px;
    padding: 18px 36px 6px 18px;
    z-index: 10;
    position: absolute;
    display: none;

    left: -18px;

    border-radius: 5px;
    background-color: #1d2126;
    -webkit-box-shadow: 4px 8px 0px 0px rgba(46, 46, 46, 0.245);
    -moz-box-shadow: 4px 8px 0px 0px rgba(46, 46, 46, 0.245);
    box-shadow: 4px 8px 12px 0px rgba(46, 46, 46, 0.245);
  }

  .selected {}

  .option {
    margin-bottom: 12px;
  }

  &.open {
    .dropdown {
      display: initial;
    }
  }
}