.aprValue {
  margin-top: 68px;
  color: #ebeced;
  font-size: 80px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 920px) {
    margin-top: 8px;
    font-size: 60px;
    letter-spacing: 3px;
  }
}

.aprTitle {
  color: #ebeced;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
}

.wrapper {
  margin: 0 auto;
  max-width: 672px;
  width: 100%;

  .mainSection {
    padding: 24px;

    .balanceWrapper {
      padding: 24px;
      border: 1px solid #76849958;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 20px;
        color: #fff;
        opacity: 0.5;
        margin-bottom: 12px;

        @media (max-width: 540px) {
          font-size: 14px;
        }
      }

      .amountWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .value {
          font-size: 40px;
          font-weight: 600;

          @media (max-width: 540px) {
            font-size: 32px;
          }

          .currency {
            opacity: 0.5;
            font-weight: 400;

            @media (max-width: 540px) {
              font-size: 20px;
            }
          }
        }

        .percentage {
          font-weight: 500;
          font-size: 24px;
          color: #8cff97;
        }
      }

      .profitUsd {
        text-align: right;
        font-size: 20px;
        opacity: 0.5;
        margin-top: 4px;
      }
    }

    .linksWrapper {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 720px) {
        flex-wrap: wrap;
      }
      .cardLink {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        width: 138px;
        background-color: rgba(198, 211, 255, 0.2);
        gap: 16px;
        border-radius: 14px;
        opacity: 0.95;

        @media (max-width: 720px) {
          flex: 0 0 48%;
          margin-bottom: 12px;
        }

        &:hover {
          opacity: 1;
        }

        .imgWrapper {
          height: 36px;
        }

        .name {
          font-size: 16px;
        }
      }
    }

    .additionalWrapper {
      margin-top: 24px;
      padding: 28px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 16px;

      .header {
        font-size: 16px;
        text-align: center;
        margin-bottom: 24px;
        font-weight: 500;
      }

      .linksTitle {
        border: 1px solid #898f9b;
        border-radius: 16px;
        padding: 18px;
        font-size: 14px;
        text-align: center;
        opacity: 0.4;

        .innerLink {
          text-decoration: underline;
        }
      }
    }
  }
}
