.wrapper {
  margin-top: 8px;
  height: 24px;
  border-radius: 28px;
  padding: 12px;
  background-color: rgba(196, 220, 255, 0.15);
  display: flex;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.9;
  position: relative;
  z-index: 1000;

  @media (max-width: 540px) {
    margin-bottom: -8px;
  }

  &.wrongNetwork {
    background-color: #7D63EB;
    color: #fff;
  }

  &:hover {
    opacity: 1;
  }

  .iconWrapper {
    height: 24px;
    padding-left: 2px;
  }
}

.menu {
  position: absolute;
  text-align: center;
  z-index: 9999;
  left: 0;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-top: 5px;
  padding: 0px 4px;
  border-radius: 5px;
  background-color: #7D63EB;
  color: #262626;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: left;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  padding: 12px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  .status {
    width: 10px;
    height: 10px;
    margin-left: auto;
    border-radius: 50%;
    background-color: green;
  }
}