.wrapper {
    display: flex;
    padding: 20px 24px;
    align-items: center;
    justify-content: space-between;
    gap: 38px;

    @media (max-width: 920px) {
        flex-direction: column;
        gap: 12px;
    }

    @media (max-width: 540px) {
        padding: 20px 24px;
    }

    .leftSection {
        display: flex;
        gap: 12px;
        align-items: center;

        .iconWrapper {
            width: 74px;
            height: 74px;

            @media (max-width: 920px) {
                display: none;
            }
        }

        .textContainer {
            .title {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 4px;
            }

            .description {
                opacity: 0.5;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .link {
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #fff;
        padding: 10px 20px;
        color: #000;
        min-width: 140px;
        font-weight: 600;
        text-transform: uppercase;
        opacity: 0.9;
        cursor: pointer;

        &:disabled {
            opacity: 0.2;
        }

        @media (max-width: 920px) {
            width: 100%;
            text-align: center;
        }
    }
}