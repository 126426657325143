.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #050505e9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 540px;
    width: 100%;
  }

  .wrapper {
    padding: 24px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
    }

    .closeButton {
      background: none;
      border: none;
      font-size: 1.5em;
      cursor: pointer;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    .content {
      padding-top: 10px;
    }
  }
}