.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  min-height: 160px;

  .header {
    display: flex;
    align-items: center;
    gap: 12px;

    .title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .description {
    margin-top: 8px;
    font-size: 12px;
    opacity: 0.7;
    line-height: 14px;
  }

  .appliedWallet {
    margin-top: 24px;
  }

  .linkBtn {
    margin-top: 16px;
    background-color: #fff;
    border-radius: 14px;
    display: flex;
    color: #000000;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap: 16px;
    padding: 10px 0;
    opacity: 0.9;
    cursor: pointer;

    &:disabled {
      opacity: 0.3;
    }

    p {
      margin-top: 1px;
    }

    .iconWrapper {
      height: 20px;
    }
  }
}
