.wrapper {
  margin: 0 auto;
  width: 100%;

  .formWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logoWrapper {
      height: 180px;
      margin-bottom: 20px;
    }

    .description {
      font-size: 14px;
      margin-top: 16px;
      opacity: 0.4;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 4px;
    }

    .connectBtns {
      width: 100%;
    }
  }
}
