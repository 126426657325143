.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.9;
}

.tooltipCircle:hover {
  opacity: 1;
}

.tooltipText {
  visibility: visible;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  line-height: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
}

.tooltipContainer:hover .tooltipText {
  opacity: 1;
  bottom: 125%;
}
