.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .table {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    border-collapse: collapse;
    width: 100%;

    .tableHeader {
      display: contents;
      font-size: 12px;

      .tableHeaderCell {
        padding: 20px 0;
        font-weight: bold;
        text-align: center;
        background-color: #f5f5f512;
        border-bottom: 1px solid #ffffff78;
      }
    }

    .tableRow {
      display: contents;

      .tableCell {
        padding: 32px;
        text-align: center;
      }
    }
  }

  .mobileTable {
    display: none;

    .mobileRow {
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      width: 100%;
      border-bottom: 1px solid #ffffff78;

      &:last-child {
        border-bottom: none;
      }
    }

    .exchangeName {
      font-weight: bold;
    }

    .fundingRate {
      text-align: right;
    }
  }

  @media (max-width: 940px) {
    .table {
      display: none;
    }

    .mobileTable {
      display: block;
      width: 100%;
    }
  }
}
