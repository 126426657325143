.wrapper {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  .stepWrapper {
    padding: 20px;
    text-align: center;
    min-height: 340px;
  }

  .description {
    font-size: 12px;
    opacity: 0.7;
    margin-top: 6px;
    margin-bottom: 40px;
  }
}