.topSection {
  margin-top: 36px;
  display: flex;
  gap: 32px;
  align-items: stretch;

  @media (max-width: 920px) {
    flex-direction: column;
  }
}

.apyWrapper {
  width: 100%;
}

.statHeader {
  font-size: 18px;
  font-weight: 600;
  padding: 20px 24px 0 24px;
}

.custodiansWrapper {
  margin-top: 32px;
}

.supplyWrapper {
  margin-top: 32px;
}

.priceWrapper {
  margin-top: 32px;
}

.fundWrapper {
  margin-top: 32px;
}

.custodianTableWrapper {
  margin-top: 32px;
}

.openPositionWrapper {
  margin-top: 32px;
}

.tokenMarginedWrapper {
  margin-top: 32px;
}

.highchartsWrapper {
  margin-top: 32px;

  .statHeader {
    padding: 0;
  }

  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px 0 24px;

    @media (max-width: 920px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
    }
  }
}

.headerSeparator {
  font-size: 40px;
  padding-top: 40px;
  text-align: center;

  @media (max-width: 920px) {
    font-size: 24px;
  }
}

.desktopOnly {
  @media (max-width: 920px) {
    display: none !important;
  }
}
