.wrapper {
  margin: 0 auto;
  width: 100%;
}

.form {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 24px;

  @media (max-width: 920px) {
    flex-direction: column;
  }
}

.response {
  position: relative;
  display: flex;
  flex-direction: column;

  pre {
    background-color: #ffffff0e;
    border-left: 3px solid #ffffff;
    color: #fff;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 12px;
    max-width: 100%;
    overflow: auto;
    padding: 24px;
    display: block;
    word-wrap: break-word;

    @media (max-width: 920px) {
      padding: 12px;
      font-size: 12px;
    }
  }
}

.dataWrapper {
  padding: 0 24px 24px 24px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  .header {
    font-size: 16px;
    font-size: 600;
  }
}

.submitBtn {
  padding: 12px 24px;
  font-size: 13px;
  text-transform: uppercase;
  background-color: rgba(196, 220, 255, 0.25);
  font-weight: 600;
  letter-spacing: 1.5px;
  border: 1px solid #778499;
  border-radius: 14px;
  opacity: 0.9;
  cursor: pointer;
  color: #fff;

  @media (max-width: 920px) {
    width: 100%;
  }
}

.copyWrapper {
  opacity: 0.8;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(196, 220, 255, 0.25);
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    width: 12px;
    height: 12px;
  }

  cursor: pointer;
}
