.selectWrapper {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.selectHeader {
  width: 100%;
  padding: 12px 24px;
  font-size: 13px;
  text-transform: uppercase;
  background-color: rgba(196, 220, 255, 0.25);
  font-weight: 600;
  letter-spacing: 1.5px;
  border: 1px solid #778499;
  border-radius: 14px;
  opacity: 0.9;
  cursor: pointer;
  color: #fff;
  text-align: left;
}

.selectList {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #778499;
  background-color: #1d2126;
  border-top: none;
  position: absolute;
  z-index: 999;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 14px 14px;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.isOpen {
  .selectHeader {
    border-radius: 14px 14px 0 0;
  }
}

.selectListItem {
  border: 1px solid #454e5e;
  padding: 12px 24px;
}

.selectListItem:last-child {
  border-bottom: none;
}

.selectListItem:hover {
  background-color: #ffffff12;
}

.errorMessage {
  padding-left: 20px;
  font-size: 12px;
  text-align: left;
  margin-top: 2px;
  font-weight: 500;
  color: #e96666;
}

.placeholder {
  opacity: 0.4;
}
