.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
}

.noData {
  text-align: center;
  opacity: 0.6;
}
